<template>
  <div class="user_board">
    <div class="board_header">
      <div class="avatar">
        <el-avatar shape="square" size="large" src="https://acg.xydwz.cn/gqapi/gqapi.php"></el-avatar>
      </div>
      <div class="title_and_name">
        <div class="title">画板222</div>
        <div class="name">来自 zhazhamao</div>
      </div>
    </div>
    <div class="topbar">
      <div :class="[{'active': topbarIndex == 0}]" @click="topbarIndex = 0">{{collectTotal}} 采集</div>
      <div :class="[{'active': topbarIndex == 1}]" @click="topbarIndex = 1">被{{total}}人喜欢</div>
    </div>
    <div class="main">
      <mp-waterfall :data="collectData" @loadmore="loadmore" v-if="topbarIndex == 0"></mp-waterfall>
      <div class="likes" v-if="topbarIndex == 1">
        <div class="subitem" v-for="(item) in total" :key="item">
          <div class="content" @click="userCenter">
            <div class="avatar">
              <img src="https://acg.xydwz.cn/gqapi/gqapi.php">
            </div>
            <div class="name">zhazhamao</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MpWaterfall from '@/mp/components/mp-waterfall';
export default {
  components: {
    MpWaterfall
  },
  data() {
    return {
      topbarIndex: 0,
      collectData: [],
      collectTotal: 0,
      query: {catId: '', username: '', ord: '', title: '', use: '', page: 1},
      total: 10,
    }
  },
  methods: {
    getCollectData() {
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.query.catId,
          username: this.query.username,
          ord: this.query.ord,
          title: this.query.title,
          use: this.query.use,
          page: this.query.page,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectTotal = res.data.count;
          if (res.data.res.length < 20) {
            this.noMore = true;
          } else {
            this.collectData = this.collectData.concat(res.data.res);
          }
        }
      })
    },
    loadmore() {
      this.query.page += 1;
      this.getCollectData();
    },
    userCenter() {
      this.$router.push({
        path: '/mCollectUser',
        query: {id: Math.random(0, 100)},
      });
    },
  },
  created() {
    this.getCollectData();
  },
}
</script>

<style lang="scss" scoped>
.user_board {
  height: 100%;
  .board_header {
    display: flex;
    background: #fff;
    padding: 10px;
    .avatar {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .title_and_name {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        color: #444;
        font-size: 1rem;
        margin-bottom: 8px;
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .name {
        font-size: .8em;
        color: #999;
      }
    }
  }
  .topbar {
    display: flex;
    line-height: 40px;
    text-align: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #999;
    background: #fff;
    > div {
      flex: 1;
      &.active {
        font-weight: 700;
        color: #444;
        background: #ededed;
      }
    }
  }
  .main {
    .likes {
      display: flex;
      flex-wrap: wrap;
      background: #f5f5f5;
      padding: 10px 0;
      .subitem {
        box-sizing: border-box;
        width: 25%;
        padding: 10px;
        @media (max-width: 768px) {
          width: 33.33%;
        }
        @media (max-width: 414px) {
          width: 50%;
        }
        @media (max-width: 360px) {
          width: 50%;
        }
        @media (max-width: 320px) {
          width: 100%;
        }
        .content {
          background: #fff;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,.02), 0 1px 3px 0 rgba(0,0,0,.02);
          text-align: center;
          .avatar {
            width: 66px;
            height: 66px;
            padding: 20px 0;
            margin: 0 auto;
            img {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              border: 3px double #ccc; 
            }
          }
          .name {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>